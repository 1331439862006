/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum BacktestTimeframe {
    WEEK = 'week',
    MONTH = 'month',
    THREEMONTHS = 'threemonths',
    SIXMONTHS = 'sixmonths',
    YEAR = 'year',
    TWOYEARS = 'twoyears',
}
