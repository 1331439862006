import React, { PropsWithChildren, useEffect, useState } from "react";
import { ITokenModel, TokenContext } from "../contexts/tokenContext";
import { AuthService, UserService } from "../api";
import { Flex, QRCode, Result, Spin } from "antd";
import { useWebApp } from "../tg-api";
import { OpenAPI } from "../api/core/OpenAPI";

export interface IResponseContainer<T> {
  isSuccess?: boolean ;
  errorText?: string | null;
  data?: T | null;
}

interface TokenProviderProps extends PropsWithChildren {
  qrCodeChildren?: React.ReactNode;
  otherMethodChildren?: React.ReactNode;
}

type AuthTokenModel =  IResponseContainer<ITokenModel> & {authMethod: "web" | "tg"}

export const TokenProvider = ({ children, qrCodeChildren, otherMethodChildren }: TokenProviderProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);
  const [session, setSession] = useState<string | undefined | null>();
  const WebApp = useWebApp();
  const [tokenResponse, setTokenResponse] = useState<AuthTokenModel>();
  let lastQrUpdateTime:Date = new Date();

 const refreshQrCode = async ()=>
 {
        setLoaded(false);
        const result = await AuthService.getApiAuthQrcode();
        if (result?.isSuccess) {
          setSession(result.data);
          setExpired(false);
          lastQrUpdateTime = new Date();
        }
        setLoaded(true);
 }
  
  useEffect(() => {
    const fetchAuthData = async () => {
      debugger
      if (WebApp?.initData === '') {
        const authDataStr = localStorage.getItem('hedger:authdata');
        if (authDataStr) {
          const authData = JSON.parse(authDataStr) as AuthTokenModel;
          if (authData.data!.expires && new Date() < new Date(authData.data!.expires)) {
            setAuthToken(authData);
            setLoaded(true);
            return;
          }
        }
        const result = await AuthService.getApiAuthQrcode();
        if (result?.isSuccess) {
          setSession(result.data);
        }
        setLoaded(true);
      } else {
        const jObject = JSON.parse(JSON.stringify(WebApp?.initDataUnsafe));
        const result = await UserService.postApiUserTokenTelegram({ requestBody: jObject });
        setAuthToken({...result, authMethod:"tg"});
        setLoaded(true);
      }
    };

    fetchAuthData();
  }, [WebApp]);

  useEffect(() => {
    if (!session) return;

    const intervalId = setInterval(async () => {
      const currentTime = new Date();
      const elapsedTime = (currentTime.getTime() - lastQrUpdateTime.getTime()) / 1000; // время в секундах
      if(elapsedTime >= 30)
      {
         clearInterval(intervalId);
         setExpired(true);
      }
      const authResponse = await AuthService.getApiAuthQrcodeCheck({ sessionId: session! });
      if (authResponse.isSuccess && authResponse.data) {
        const tokenResponse = await AuthService.postApiAuthQrcodeLogin({ sessionId: session! });
        const authData : AuthTokenModel= {...tokenResponse, authMethod:"web"};
        setAuthToken(authData);
        setSession(undefined);
        localStorage.setItem('hedger:authdata', JSON.stringify(authData));
        clearInterval(intervalId);
      }
    }, 2000);
    return () => clearInterval(intervalId);
  }, [session]);

  const setAuthToken = (authData: AuthTokenModel) => {
    OpenAPI.TOKEN = authData?.data?.token;
    setTokenResponse(authData);
  };

  const logout = () => {
    localStorage.removeItem('hedger:authdata');
    setTokenResponse(undefined);
    OpenAPI.TOKEN = undefined;    
    window.location.replace('/');
  };

  return (
    <TokenContext.Provider value={{ ...tokenResponse?.data!, logout } ?? null}>
      {!loaded && (
        <div className="flex justify-center content-center h-full w-full">
          <Spin tip="Loading..." />
        </div>
      )}
      {loaded && !tokenResponse && session && (
        <Flex justify="center">
          <Flex align="center" vertical>
            <h2>Scan this QR code to log in</h2>
            {/*<img style={{ maxWidth: "200px" }} src={`data:image/png;base64,${session?.qrCodeData}`} alt="QR Code" />*/}
            <QRCode value={session} status={!loaded?"loading":(expired? "expired": "active")} onRefresh={() => refreshQrCode()} />
          </Flex>
        </Flex>
      )}
      {loaded && tokenResponse?.isSuccess && tokenResponse.authMethod === "tg" && otherMethodChildren}
      {loaded && tokenResponse?.isSuccess  && tokenResponse.authMethod === "web" && qrCodeChildren}
      {loaded && tokenResponse && !tokenResponse.isSuccess && (
        <Result
          status="403"
          title="403"
          subTitle={
            tokenResponse?.errorText ?? "К сожалению, у вас нет прав доступа к этой странице."
          }
        />
      )}
      {loaded && tokenResponse?.isSuccess && children}
    </TokenContext.Provider>
  );
};
