export class RoutePaths {
  static NoInternet = {
    Path: "/no-internet"    
  };
  static Exchanges = {
    Path: "/exchanges",
    View: {
      Path: "/exchanges/:id",
    },
    Create: {
      Path: "/exchanges/create",
    },
  };
  static Positions = {
    Path: "/positions",
    View: {
      Path: "/positions/:id",
    },
    Edit: {
      Path: "/positions/edit/:id",
    },
    Create: {
      First: { Path: "/positions/create/first-part" },
      Second: { Path: "/positions/create/second-part" },
    },
  };
  static Scenarios = {
    Path: "/scenarios",
    View: {
      Path: "/scenarios/:id",
    },
    Edit: {
      Path: "/scenarios/edit/:id",
    },
    Create: {
      First: { Path: "/scenarios/create/first-part" },
      Second: { Path: "/scenarios/create/second-part" },
      Options: { Path: "/scenarios/create/options-part" },
    },
  };
  static Wallets = {
    Path: "/wallets",
    View: {
      Path: "/wallets/:id",
    },
    Edit: {
      Path: "/wallets/edit/:id",
    },
    Create: {
      Path: "/wallets/create",
    },
  };
  static Earnings = {
    Path: "/earnings",
  };
  static Team = {
    Path: "/team",
  };
  static Setting = {
    Path: "/setting",
  };
  static Monitor = {
    Path: "/monitor",
  };
  static Statistic = {
    Path: "/statistic",
  };
  static MyPositions = {
    Path: "/mypositions",
  };
  static Backtest = {
    Path: "/backtest",
  };
}
