import { Flex } from "antd"
import "./index.css"


export type KeyValueItemProps = {
    lowerPrice: number,
    upperPrice: number,
    currentPrice: number,    
    currentCexPrice: number,
    shift: number,
    edgePoints :number[]
}


export const PoolState = (props: KeyValueItemProps) => {
    const flipped = props.shift !== props.edgePoints[1];
    const width = props.upperPrice - props.lowerPrice
    const priceA = props.lowerPrice + width * (flipped ? 100 - props.edgePoints[2] : props.edgePoints[0]) / 100;
    const priceB = props.lowerPrice + width * (flipped ? 100 - props.edgePoints[0] : props.edgePoints[2]) / 100;
    const priceStart = props.lowerPrice + width * (flipped ? 100 - props.edgePoints[1] : props.edgePoints[1]) / 100;
    const leftEmptyWidth = priceA - props.lowerPrice
    const rightEmptyWidth =  props.upperPrice - priceB
    const startWidth = priceStart - props.lowerPrice
    const currentWidth = props.currentPrice - props.lowerPrice
    const currentCexWidth = props.currentCexPrice - props.lowerPrice
    const a = Math.round(leftEmptyWidth*1000/width)/10
    const b = Math.round(rightEmptyWidth*1000/width)/10

    return <div className="pool-state container">
        <Flex className="rails flex justify-between" >
            <div style={{backgroundColor:"#454545", width:`${a}%`, borderRadius:"2px 0px 0px 2px"}}></div>
            <div style={{backgroundColor:"#518139", width: `${100-(a+b)}%`} } ></div>
            <div style={{backgroundColor:"#454545", width:`${b}%`, borderRadius:"2px 0px 0px 2px"}}></div>
        </Flex>
        <div className="current-price" style={{left:`${Math.round(currentWidth*1000/width)/10}%`}}></div>
        <div className="current-cex-price" style={{left:`${Math.round(currentCexWidth*1000/width)/10}%`}}></div>
        <div className="start-price" style={{left:`${Math.round(startWidth*1000/width)/10}%`}}></div>
    </div>
}
