/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SessionDtoListResponseData } from '../models/SessionDtoListResponseData';
import type { SessionStateDtoListResponseData } from '../models/SessionStateDtoListResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SessionService {
    /**
     * Получить список сессий
     * @returns SessionDtoListResponseData Success
     * @throws ApiError
     */
    public static getApiSession({
        scenarioId,
        activeOnly,
    }: {
        scenarioId: number,
        activeOnly?: boolean,
    }): CancelablePromise<SessionDtoListResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/session/{scenarioId}',
            path: {
                'scenarioId': scenarioId,
            },
            query: {
                'activeOnly': activeOnly,
            },
        });
    }
    /**
     * Получить список активных сессий
     * @returns SessionDtoListResponseData Success
     * @throws ApiError
     */
    public static getApiSessionActive(): CancelablePromise<SessionDtoListResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/session/active',
        });
    }
    /**
     * Получить список активных сессий
     * @returns SessionStateDtoListResponseData Success
     * @throws ApiError
     */
    public static getApiSessionActiveState({
        sharedSessions,
    }: {
        sharedSessions?: boolean,
    }): CancelablePromise<SessionStateDtoListResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/session/active/state',
            query: {
                'sharedSessions': sharedSessions,
            },
        });
    }
}
