/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BacktestReportResponseIEnumerableResponseData } from '../models/BacktestReportResponseIEnumerableResponseData';
import type { BacktestTimeframe } from '../models/BacktestTimeframe';
import type { TokenDtoIEnumerableResponseData } from '../models/TokenDtoIEnumerableResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RetroService {
    /**
     * Отчет моделирует как будет меняться депозит в пуле ликвидности на реальных старых данных
     * @returns BacktestReportResponseIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiRetro({
        amountStable,
        ratioMin,
        ratioMax,
        token0Id,
        token1Id,
        channelWidthMin,
        channelWidthMax,
        exitTriggerMin,
        exitTriggerMax,
        poolChangeCountRangeMin,
        poolChangeCountRangeMax,
        switchDirection,
        center,
        apyMin,
        apyMax,
        timeframeList,
        poolChangePrice,
        swapFee,
    }: {
        amountStable?: number,
        ratioMin?: number,
        ratioMax?: number,
        token0Id?: number,
        token1Id?: number,
        channelWidthMin?: number,
        channelWidthMax?: number,
        exitTriggerMin?: number,
        exitTriggerMax?: number,
        poolChangeCountRangeMin?: number,
        poolChangeCountRangeMax?: number,
        switchDirection?: boolean,
        center?: boolean,
        apyMin?: number,
        apyMax?: number,
        timeframeList?: Array<BacktestTimeframe>,
        poolChangePrice?: number,
        swapFee?: number,
    }): CancelablePromise<BacktestReportResponseIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/retro',
            query: {
                'amountStable': amountStable,
                'ratioMin': ratioMin,
                'ratioMax': ratioMax,
                'token0Id': token0Id,
                'token1Id': token1Id,
                'channelWidthMin': channelWidthMin,
                'channelWidthMax': channelWidthMax,
                'exitTriggerMin': exitTriggerMin,
                'exitTriggerMax': exitTriggerMax,
                'poolChangeCountRangeMin': poolChangeCountRangeMin,
                'poolChangeCountRangeMax': poolChangeCountRangeMax,
                'switchDirection': switchDirection,
                'center': center,
                'apyMin': apyMin,
                'apyMax': apyMax,
                'timeframeList': timeframeList,
                'poolChangePrice': poolChangePrice,
                'swapFee': swapFee,
            },
        });
    }
    /**
     * @returns TokenDtoIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiRetroNettokens({
        netId,
    }: {
        netId: number,
    }): CancelablePromise<TokenDtoIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/retro/nettokens',
            query: {
                'netId': netId,
            },
        });
    }
}
