import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Button, Flex, Form, Input, InputNumber, Result, Slider, Spin, Switch, Typography } from "antd";
import { SubmitButton } from "feature";
import { FirstFormFullModel, SecondFormModel } from "../../../model";
import { useEffect, useState } from "react";
import {
  AttributeDescription,
  BalanceInfo,
  RoutePaths,
  ScenarioService,
  ScenarioSimpleDto,
  StrategyType,
  WalletService,
  round,
  useShowPopup,
} from "shared";
import "../../index.css"

const prepareDescriptions = (decriptions: Record<string, AttributeDescription>) => {
  Object.keys(decriptions).forEach(key => {
    const item = decriptions[key]
    if ((item.type?.startsWith("RangeWithPointValue") ||
      item.type?.startsWith("RangeWithPointValue"))
      && item.defaultValue) {
      item.defaultValue = JSON.parse(item.defaultValue)
    }
  })
}

const getFormItem = (attribute: AttributeDescription, props: any) => {
  if (attribute.type?.startsWith("RangeWithPointValue")) {
    return <Slider
      range
      tooltip={{ open: true }}
      style={{ marginTop: "40px" }}
      trackStyle={[
        { backgroundColor: '#ff5c53' },
        { backgroundColor: '#9dff69' },
      ]}
    />
  }
  else {
    switch (attribute.type) {
      case 'Decimal':
      case 'Int32':
      case 'Byte':
      case 'Float':
      case 'Double':
        return <InputNumber
          min={attribute.range?.minimum}
          max={attribute.range?.maximum}
          step={attribute.range?.minimum === 0 ? 1 : attribute.range?.minimum}
          {...props} />
      case "Boolean":
        return <Switch />
      default:
        return <Input minLength={attribute.range?.minimum ?? undefined} maxLength={attribute.range?.maximum ?? undefined} />
    }
  }
}

export const ScenarioCreatePartSecond = () => {
  const [form] = Form.useForm<any>();

  const location = useLocation();
  const state = location.state as FirstFormFullModel;
  const isOption = state.strategyType?.search("Options");
  const needHedgeValue = Form.useWatch("NeedHedge", form);
  const [loading, setLoading] = useState<boolean>(true);

  const [walletBalance, setWalletBalance] = useState<BalanceInfo>({});
  useEffect(() => {
    const request = {
      userWalletId: state.wallet.id!,
      userExchangeId: state.exchange.id,
      strategyType: state.strategyType! as StrategyType,
      netId: state.network.id!,
      tokens: [state.tokenFirst.id!, state.tokenSecond.id!]
    };

    WalletService.getApiWalletBalance(request).then((result) => {
      if (result.isSuccess) {
        setWalletBalance(result?.data!);
      }
      else {
        showPopUp({
          title: "Ошибка",
          message: result?.errorText ?? "",
        });
      }
      setLoading(false);
    });
  }, []);

  const [attributes, setAttributes] = useState<Record<string, AttributeDescription>>({});
  useEffect(() => {

    ScenarioService.getApiScenarioStrategy({ name: state.strategyType! }).then((result) => {
      prepareDescriptions(result?.data!)
      setAttributes(result?.data!);
    });
  }, []);


  const showPopUp = useShowPopup();
  const navigate = useNavigate();

  useEffect(() => {
    const savedData = localStorage.getItem('secondStepData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      form.setFieldsValue({
        name: parsedData.name,
        amount: parsedData.amount,
        ...parsedData.strategy,
      });
    }
  }, []);

  const onFinish = async (values: any) => {
    if (isOption && isOption >= 0 && needHedgeValue) {
      const fullState: SecondFormModel = {
        ...state,
        name: values.name,
        amount: values.amount,
        strategy: values
      };
      localStorage.setItem('secondStepData', JSON.stringify(fullState)); // Save state to localStorage
      location.state = fullState;
      navigate(RoutePaths.Scenarios.Create.Options.Path, { state: fullState });
    }
    else {
      const request: ScenarioSimpleDto = {
        netId: state.network.id!,
        swapperId: state.swapper.id!,
        feeTier: state.pool.feeTier!,
        poolAddress: state.pool.id!,
        token0Id: state.tokenFirst.id!,
        token1Id: state.tokenSecond.id!,
        userExchangeId: state.exchange.id!,
        userWalletId: state.wallet.id,
        amount: values.amount,
        strategyType: state.strategyType as StrategyType,
        name: values.name,
        isReversed: state.pool.reversed ?? false,
        strategy: values
      };
      delete request.strategy.name
      delete request.strategy.amount
      try {
        const response = await ScenarioService.postApiScenario({
          requestBody: request,
        });

        if (response && !response?.isSuccess) {
          return showPopUp({
            title: "Ошибка",
            message: response?.errorText ?? "",
          });
        }

        navigate(-2);
      } catch (error: any) {
        if (error) {
          return showPopUp({ title: "Ошибка", message: error.message });
        }
      }
    }
  };

  type SizeType = "small" | "middle" | "large" | undefined;
  const props = {
    min: 0,
    size: "large" as SizeType,
    type: "number",
    className: "w-full",
    disabled: loading,
    suffix: <>{loading && <LoadingOutlined style={{ fontSize: 12 }} spin />}</>,
  };

  return (
    <><Alert
      type="info"
      message={
        <Flex gap={'small'} vertical>
          <Spin spinning={loading} size="small">
            <Flex vertical><div><b>DEX баланс</b> ~ {walletBalance.dexBalance} $</div>
              {walletBalance.dexBalanceDetails &&
                <div style={{ fontSize: 'x-small', margin: -10, opacity: 0.6 }}>
                  <ul>
                    {Object.keys(walletBalance.dexBalanceDetails!).map(key =>
                      <li key={key}><b>{key}</b> ~{walletBalance.dexBalanceDetails![key].valueUSD}$</li>)}
                  </ul>
                </div>}
            </Flex>
            <Flex><b>CEX баланс</b> ~ {walletBalance.cexBalance} $</Flex>
          </Spin>
        </Flex>}
      className="mb-3"
    />
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        Текущая цена: {round(state.pool.currentPrice ?? 0, 2)}
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label={"Наименование"}
          rules={[{ required: true }]}
        >
          <Input {...props} type="text" />
        </Form.Item>
        <Form.Item
          name="amount"
          label={"Сумма вклада"}
          rules={[{ required: true }]}
        >
          <InputNumber {...props} />
        </Form.Item>
        {Object.keys(attributes!).map((key: string) =>
          <Form.Item
            key={key}
            name={key}
            label={attributes[key].description ?? key}
            initialValue={attributes[key].defaultValue}
            rules={[{ required: attributes[key].required }]}
          >
            {
              getFormItem(attributes[key], { ...props })
            }
          </Form.Item>)
        }
        <Form.Item>
          <SubmitButton form={form} text={isOption && needHedgeValue ? "Далее" : "Сохранить"} isMain={true} />
        </Form.Item>
      </Form>
    </>
  );
};
