import { InputNumber} from "antd";
import { round } from "lodash";

export const CustomInputNumber = (props: any) => {
   const { value, onChange, step = 1, min, max } = props;
   const decimals = Math.abs(Math.log10(step))
    const handleIncrease = () => {
      if (max === undefined ||(value + step) <= props.max) {
        onChange(round(value + step, decimals));
      }
    };
  
    const handleDecrease = () => {
      if (min === undefined || value - step >= min) {
        onChange(round(value - step, decimals));
      }
    };
  
    return (
        <InputNumber
          {...props}
          value={value}
          onChange={onChange}
          step={step}
          min={min}
          max={max}
          addonBefore={
            <div onClick={()=>{ if(!min || value > min) handleDecrease()}} >
          -
            </div>
          }
          addonAfter={
            <div onClick={()=>{ if(!max || value < max) handleIncrease()}} >
          +
            </div>
          }
        />
    );
  };
  
  export default CustomInputNumber;