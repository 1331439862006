import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { RoutePaths, BackButton, SettingButton } from "shared";

import { Main } from "./main";
import { Setting } from "./setting";
import { Wallet } from "./wallets";
import { Exchange } from "./exchange";
import { Position } from "./positions";
import { Earning } from "./earnings";
import { Scenario } from "./scenarios";
import { NoInternetPage } from "./NoInternetPage";
import { SessionStates } from "widgets/sessions";

const MobileRoutingPages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [backBtnState, setBackBtnState] = useState<boolean>(false);

  useEffect(() => {
    const state = location?.key !== "default";
    setBackBtnState(state);
  }, [location]);

  return (
    <div className="mobile-app">
      <Routes>
        <Route path="" element={<Main />} />

        <Route
          path={RoutePaths.Exchanges.Path}
          element={<Exchange.ExchangeList />}
        />
        <Route
          path={RoutePaths.Exchanges.View.Path}
          element={<Exchange.ExchangeView />}
        />
        <Route
          path={RoutePaths.Exchanges.Create.Path}
          element={<Exchange.ExchangeCreate />}
        />
        <Route
          path={RoutePaths.Scenarios.Path}
          element={<Scenario.ScenarioList />}
        />
        <Route
          path={RoutePaths.Scenarios.View.Path}
          element={<Scenario.ScenarioView />}
        />
        <Route
          path={RoutePaths.Scenarios.Create.First.Path}
          element={<Scenario.ScenarioCreatePartFirst />}
        />
        <Route
          path={RoutePaths.Scenarios.Create.Second.Path}
          element={<Scenario.ScenarioCreatePartSecond />}
        />
        <Route
          path={RoutePaths.Scenarios.Create.Options.Path}
          element={<Scenario.OptionSettingsPart />}
        />
        <Route
          path={RoutePaths.Scenarios.Edit.Path}
          element={<Scenario.ScenarioEdit />}
        />
        <Route
          path={RoutePaths.Positions.Path}
          element={<Position.PositionList />}
        />
        <Route
          path={RoutePaths.Positions.View.Path}
          element={<Position.PositionView />}
        />
        <Route
          path={RoutePaths.Positions.Edit.Path}
          element={<Position.PositionEdit />}
        />
        <Route
          path={RoutePaths.Positions.Create.First.Path}
          element={<Position.PositionCreatePartFirst />}
        />
        <Route
          path={RoutePaths.Positions.Create.Second.Path}
          element={<Position.PositionCreatePartSecond />}
        />

        <Route path={RoutePaths.Wallets.Path} element={<Wallet.WalletList />} />
        <Route
          path={RoutePaths.Wallets.View.Path}
          element={<Wallet.WalletView />}
        />
        <Route
          path={RoutePaths.Wallets.Edit.Path}
          element={<Wallet.WalletEdit />}
        />
        <Route
          path={RoutePaths.Wallets.Create.Path}
          element={<Wallet.WalletCreate />}
        />

        <Route
          path={RoutePaths.Earnings.Path}
          element={<Earning.EarningList title={"Заработок"} />}
        />
        <Route
          path={RoutePaths.Team.Path}
          element={<SessionStates sharedSessions={true} />}
        />
        <Route path={RoutePaths.Setting.Path} element={<Setting />} />
        <Route path={RoutePaths.NoInternet.Path} element={<NoInternetPage />} />
      </Routes>
      {backBtnState && <BackButton onClick={() => navigate(-1)} />}
      <SettingButton onClick={() => navigate(RoutePaths.Setting.Path)} />
    </div>
  );
};

export default MobileRoutingPages;
